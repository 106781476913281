import React, { useEffect, useRef, useState } from 'react';

import { ROLE, BASEURL } from '../constant';
import getApi from '../api/getApi';
import MyDataTableEdit from './DataTableEdit';

interface Market {
  value: string;
  label: string;
  id?: string;
}

interface User {
    value: string;
    label: string;
    id?: string;
  }


interface MarketRows {
    market_id: number;
    name: string;
  }

interface DataRow {
    'user_id': string;
    'name': string;
    'markets': MarketRows[];
    [key: string]: any;
  }

export default function UserMarketControl() {
  const [markets, setMarkets] = useState<Market[]>([]);
  const [ marketNames, setMarketNames ] = useState<MarketRows[]>([])
  const [users, setUsers] = useState<User[]>([]);
  const tableDataRef = useRef<{ columns: any[]; rows: [] }>({ columns: [], rows: [] });
  const role = localStorage.getItem('role')

  const createMarketsRows = (col: string, row: DataRow) => {
    if(col === 'markets'){
       return (row[col].map((obj) => {
            return(<div className='row'>
                 <i className="bi bi-pencil-square">{obj['name']}</i>
            </div>)
        }))
    }else{
        return row[col]
    }
  }


  const createColumns = (columns: string[]) => {
    const cols: any[] = [];
    columns.forEach((col) => {
        cols.push({
          name: col,
          sortable: true,
          cell: (row: DataRow) => createMarketsRows(col, row),
        });
    });
    return cols;
  };

  useEffect(() => {
    const getMarketNames = async () => {
      const response = await getApi(`${BASEURL}/markets_names?role=${role}`);
      const marketOutput: Market[] = [];
      const marketName: MarketRows[] = []; 
      if (response && response.data) {
        response.data.forEach((colObj: any) => {
          const market = colObj['market']
          marketName.push({'name': market, 'market_id': colObj['id']})
          marketOutput.push({ value: market, label: market, id: colObj['id'] });
        });
        setMarketNames(marketName)
        setMarkets(marketOutput);
      } else {
        const error = response.error || 'Network error!';
        // setFlashContent({ message: error, status: 'danger' });
      }
    };

    const getUsers = async () => {
        const response = await getApi(`${BASEURL}/get_users`);
        const userOutput: User[] = [];
        if (response && response.data) {
            response.data.forEach((colObj: any) => {
            const market = colObj['user']
            userOutput.push({ value: market, label: market, id: colObj['id'] });
            });
            setUsers(userOutput);
        } else {
            const error = response.error || 'Network error!';
            // setFlashContent({ message: error, status: 'danger' });
        }
    }

    const getUserAceess = async () => {
        const response = await getApi(`${BASEURL}/get_users_with_markets`);
        if (response?.data?.length > 0) {
            console.log(response.data)
            const activeEvents = response.data;
            const cols = Object.keys(activeEvents[0]);
            // cols.push('Edit');
            // cols.push('Mark Complete', 'Dashboard');
            tableDataRef.current['columns'] = createColumns(cols);
            tableDataRef.current['rows'] = activeEvents;
        } else {
            tableDataRef.current = { columns: [], rows: [] };
            // setFlashContent({ message: activeEventsResp.message, status: 'danger' })
        }
    }

    getMarketNames();
    getUsers();
    getUserAceess();
  }, [])  
  

  return (
    <>
        <div className="row admin-page">
            <div className="col-12 registration-text">
                <h2>User Market Control</h2>
            </div>
        </div>

        <div className='user-market-info'>
            <MyDataTableEdit columns={tableDataRef.current['columns']} 
            rows={tableDataRef.current['rows']} title="" allMarkets={marketNames} />

        </div>
    </>
  );
}