import React, { useState, useEffect } from 'react';

// Define a type for the contentList items
type ContentListItem = string | { author: string; content: string };

// Define a type for the component props
interface SlideShowProps {
  contentList: ContentListItem[];
  type?: 'img' | 'text'; // Assuming type can be either 'img' or 'text'
}

const SlideShow: React.FC<SlideShowProps> = ({ contentList, type }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === contentList.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3000 milliseconds (3 seconds)

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [contentList.length]);

  return (
    <div className='slide-content'>
      {type === 'img' && typeof contentList[currentImageIndex] === 'string' ? (
        <img src={contentList[currentImageIndex] as string} alt="Slideshow" />
      ) : (
        <div>
          {typeof contentList[currentImageIndex] === 'object' && (
            <>
              <div>
                <h5>Testimonials</h5>
              </div>
              <div className='nmass-slide-content'>
                {(contentList[currentImageIndex] as { author: string; content: string }).content}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SlideShow;
