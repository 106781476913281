

export let accessToken: string | null = null;

export type LoginRespData = {
    firstname: string;
    lastname: string;
    role: string;
    email: string;
    access_token: string;
    id: string;
};

export const getAccessToken = () => {
    if (!accessToken) {
        accessToken = localStorage.getItem('accessToken') || null;
    }
    return accessToken;
};

export const login = (data: LoginRespData, access_token: string ) => {
    localStorage.setItem('userName', `${data.lastname}, ${data.firstname}`);
    localStorage.setItem('email', `${data.email}`);
    localStorage.setItem('role', data.role);
    localStorage.setItem('accessToken', access_token);
    localStorage.setItem('userid', data.id);
};

export const logout = () => {
    accessToken = null;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('role');
    localStorage.removeItem('email');
    localStorage.removeItem('userid');
    //window.location.reload();
};