import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from './images/circet_logo.png';
import 'bootstrap/js/dist/dropdown';
import NmaasHome from './NmaasHome';
import Events from './Events';
import UserRegistration from './components/UserRegistration';
import AddUpcomingEvents from './components/AddUpcomingEvents';
import UserMarketControl from './components/UserMarketControl';
import { SIDEMENU, ADMINICON, SIDEMENU_SUB } from './constant';
import AboutUs from './AboutUs';
import { logout as authLogout, getAccessToken } from './auth';

export default function Home() {
  const [page, setPage] = useState<string>('Home');
  const navigate = useNavigate();

  const userName = localStorage.getItem('userName');
  const role = localStorage.getItem('role');

  if ( !getAccessToken() ) {
    navigate('/login');
  }

  if (role === 'admin') {
    (SIDEMENU as any)['Admin'] = ADMINICON;
  }

  const logout = () => {
    authLogout();
    delete (SIDEMENU as any)['Admin'];
    navigate('/login');
  };

  const changePage = (tab: string) => {
    if(tab === 'Admin'){
      return
    }else{
      setPage(tab);
    }
  };

  return (
    <div className="row container-fluid">
      <div className="col-md-2 bg-dark col-auto min-vh-100 d-flex justify-content-between flex-column">
        <div>
          <a className="d-flex align-itemcenter d-none d-sm-inline">
            <img className="logo d-none d-sm-inline" src={logo} alt="Logo" />
          </a>
          <ul className="nav nav-pills flex-column">
            <li className="nav-item text-white fs-4">
              {Object.keys(SIDEMENU).map((key, ind) => {
                return (
                  <div key={ind} className="nav-item-container">
                    <a
                      href="#"
                      onClick={() => changePage(key)}
                      className={`nav-link text-white fs-5 ${page === key ? 'active_page' : ''}`}
                      aria-current="page"
                    >
                      <i className={(SIDEMENU as any)[key]}></i>
                      <span className="ms-3 d-none d-sm-inline">{key}</span>
                      {key === 'Admin' && <span className="arrow">▼</span>}
                    </a>
                    {key === 'Admin' && (
                      <ul className="submenu">
                        {SIDEMENU_SUB.map((subKey, subInd) => (
                          <li key={subInd}>
                            <a
                              href="#"
                              onClick={() => changePage(subKey)}
                              className="nav-link text-white fs-6"
                            >
                              {subKey}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                );
              })}
            </li>
          </ul>
        </div>
        <div className="dropdown open">
          <a
            className="text-decoration-none text-white dropdown-toggle p-3"
            type="button"
            id="triggerId"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="bi bi-person-circle"></i>
            <span className="ms-2 d-none d-sm-inline">{userName}</span>
          </a>
          <div className="dropdown-menu" aria-labelledby="triggerId">
            <div className="dropdown-item" onClick={logout}>
              Logout
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-10 forms-container">
        {page === 'Home' && <NmaasHome />}
        {page === 'Events' && <Events />}
        {page === 'User Registration' && <UserRegistration />}
        {page === 'Add Upcoming Events' && <AddUpcomingEvents />}
        {page === 'User Market Control' && <UserMarketControl />}
        {page === 'About Us' && <AboutUs />}
      </div>
    </div>
  );
}
