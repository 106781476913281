import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';
// import { Progress } from 'react-sweet-progress';

import getApi from './api/getApi';
import postApi from './api/postApi';
import NmaasModal from './components/NmaasModal';
import DynamicForm from './components/DyanamicForm';
import FlashMessage, { FlashStatus } from './components/FlashMessage';
import MyDataTable from './components/DataTable';
import { BASEURL, VENDOR_NAMES, FIELDS, HIDE_COLUMNS, HIDE_ADD_UPLOAD } from './constant';
import FileUpload from './components/FileUpload';
import CircularProgress from './components/CircularProgress';
import NmaasPowerBIReport from './components/NmaasPowerBiReport';
import { getAccessToken } from './auth';

interface Market {
  value: string;
  label: string;
  id?: string;
}

interface Vendor {
  value: string;
  label: string;
  id: string;
}

interface AreaEventCodes {
  [key: string]: string[];
}

interface PowerBIInfo {
  'Event Name': string;
  token: string;
  embed_url: string;
  [key: string]: any;
}

interface DataRow {
  'Event Name': string;
  'Event AreaCode': string;
  'Market Name': string;
  'Status': string;
  [key: string]: string;
}


type ShowColumn = 'Mark Complete' | 'COP' | 'Dashboard';

function Events() {
  const [showModal, setShowModal] = useState(false);
  const [showDashModal, setShowDashModal] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [markets, setMarkets] = useState<Market[]>([]);
  const [eventAreaCode, setEventAreaCode] = useState('');
  const [eventName, setEventName] = useState('');
  const [checked, setChecked] = useState(true);
  const [areaEventCodes, setAreaEventCodes] = useState<AreaEventCodes>({});
  const [selectedOption, setSelectedOption] = useState<Market | null>({ value: '', label: '', id: ""});
  const [flashContent, setFlashContent] = useState<{message:string,status?:FlashStatus}>({ message: '' });
  const [vendor, setVendor] = useState('');

  const powerBIInfoRef = useRef<PowerBIInfo | null>(null);
  const tableActiveDataRef = useRef<{ columns: any[]; rows: DataRow[] }>({ columns: [], rows: [] });
  const tableCompleteDataRef = useRef<{ columns: any[]; rows: DataRow[] }>({ columns: [], rows: [] });
  const tableUpcomingDataRef = useRef<{ columns: any[]; rows: DataRow[] }>({ columns: [], rows: [] });
  const marketIDRef = useRef<string>('');
  const titleRef = useRef<string>('');
  const selectedColRef = useRef<string>('')
  const role = localStorage.getItem('role');
  const hideTableColumns: string[] = ['id', 'EventAreaCode', 'Market'];

  const getMarketNames = async () => {
    setShowProgress(true);
    const userId = localStorage.getItem('userid')
    const response = await getApi(`${BASEURL}/markets_names?user_id=${userId}&role=${role}`);
    setShowProgress(false);
    const marketOutput: Market[] = [];
    if (response && response.data) {
      response.data.forEach((colObj: any) => {
        const market = colObj['market']
        marketOutput.push({ value: market, label: market, id: colObj['id'] });
      });
      setMarkets(marketOutput);
    } else {
      const error = response.error || 'Network error!';
      setFlashContent({ message: error, status: 'danger' });
    }
  };

  const getAreaEventCodes = async () => {
    setShowProgress(true);
    const response = await getApi(`${BASEURL}/event_area_code`);
    setShowProgress(false);
    if (response && response.data) {
      setAreaEventCodes(response.data);
    } else {
      const error = response?.message || 'Network error';
      setFlashContent({ message: error, status: 'danger' });
    }
  };

  const addEvent = async () => {
    await getAreaEventCodes();
    titleRef.current = 'Event Form';
    setShowModal(true);
  };

  const uploadData = () => {
    titleRef.current = 'Upload the data';
    setShowModal(true);
  };

  const handleChange = () => {
    setChecked(!checked)
  }
 
  const getFormData = async (formData: any, selectedMarket: Market, selectedDate: string, selectedVendor: Vendor, selectedEndDate: string) => {
    const payload = { ...formData };
    payload['StartDate'] = selectedDate;
    payload['EndDate'] = selectedEndDate;
    payload['MarketID'] = selectedMarket.id;
    payload['Vendor'] = selectedVendor.value;
    payload['Status'] = 'Active';
    try {
      const res = await postApi(`${BASEURL}/event_register`, payload);
      if(res.status >= 200 && res.status < 300){
        console.log(selectedOption, selectedOption?.value !== "")
        if(selectedOption?.value !== ""){
          getEventActiveData(selectedOption);
        }
        setFlashContent({ message: res.data.message, status: 'success' });
      }else{
        setFlashContent({ message: res.data.message, status: 'danger' });
      }
     
    } catch (error) {
      console.log(error)
      setFlashContent({ message: String(error), status: 'danger' });
    }
  };

  const showReports = async (row: DataRow, flag: string) => {
    selectedColRef.current = flag
    setShowProgress(true);
    const payload = {'vendor': row['Vendor'], 'report_type': flag}
    try{
      const response = await postApi(`${BASEURL}/generate_token_embed_url`, payload);
      if (response && response.data) {
        powerBIInfoRef.current = { 
          ...row,
          token: response.data.token,
          embed_url: response.data.embed_url,
        };
        setShowDashModal(true);
      } else {

        const error = response.error || 'Network error!';
        setFlashContent({ message: error, status: 'danger' });
      }
    }catch(err){
      setFlashContent({ message: String(err), status: 'danger' });
    }
    
    setShowProgress(false);
  };

  const showMarkasAsComplete = (row: DataRow) => {
    setEventAreaCode(row['EventAreaCode']);
    setVendor(row['Vendor'])
    titleRef.current = 'confirm';
    setShowModal(true);
  };

  const subscribeMarketEvent = async (row: any) => {
    setShowProgress(true)
    try {
        row['name'] = localStorage.getItem('userName')
        row['email'] = localStorage.getItem('email')
        row['message'] = `Following customer in the ${row['Market']} market, Would like to subscribe for the ${row['Event Name']} event Starting On: ${row['Start Date']} and Ending On: ${row['End Date']}`
        const res = await postApi(`${BASEURL}/send_subscription`, row)
        setShowProgress(false)
        if(res.status >= 200 && res.status < 300){
          await getUpcomingEvents(marketIDRef.current)
          // setFlashContent({ message: res.data.message, status: "success" })
          
        }else{
          setFlashContent({ message: res.data.message, status: "danger" })
        }
    } catch (err) {
      setFlashContent({ message: String(err), status: "danger" })
    }
  }

  const showIcons = (col: ShowColumn, row: DataRow) => {
    if (col === 'Mark Complete') {
      return (
        <div className="row markas-complete" onClick={() => showMarkasAsComplete(row)}>
          <i className="bi bi-pencil-square"></i>
        </div>
      );
    } else if (col === 'COP') {
      return (
        <div className="row poc" onClick={() => showReports(row, 'COP')}>
          <i className="bi bi-file-earmark-text"></i>
        </div>
      );
    } else if (col === 'Dashboard') {
      return (
        <div className="row dasboard" onClick={() => showReports(row, 'dashboard')}>
          <i className="bi bi-file-earmark-bar-graph-fill"></i>
        </div>
      );
    } else if (col === 'Subscribe') {
      return (
        <div className="row subscribe" onClick={() => subscribeMarketEvent(row)}>
          <button>Subscribe</button>
        </div>
      );
    
    }else {
      return row[col];
    }
  };

  const createColumns = (columns: string[]) => {
    const cols: any[] = [];
    if (role === 'customer') {
      const updatedColumns = columns.filter((item) => !HIDE_COLUMNS.includes(item));
      columns = updatedColumns;
    }
    columns.forEach((col) => {
      if (!hideTableColumns.includes(col)) {
        cols.push({
          name: col,
          selector: (row: DataRow) => row[col],
          sortable: true,
          cell: (row: DataRow) => showIcons(col as ShowColumn, row),
        });
      }
    });
    return cols;
  };

  const getCompleteEvents = async (id: string, event_name: string) => {
    setShowProgress(true);
    let params = `event_name=${event_name}`;
    if (id) {
      params = `market_id=${id}`;
    }
    try {
      const res = await getApi(`${BASEURL}/get_complete_events?${params}`);
      if (res && res.data && res.data.length > 0) {
        const cols = Object.keys(res.data[0]);
        cols.push('COP', 'Dashboard');
        tableCompleteDataRef.current['columns'] = createColumns(cols);
        tableCompleteDataRef.current['rows'] = res.data;
      } else {
        tableCompleteDataRef.current = { columns: [], rows: [] };
        // setFlashContent({ message: res.message, status: 'danger' });
      }
    } catch (err) {
      tableCompleteDataRef.current = { columns: [], rows: [] };
      setFlashContent({ message: String(err), status: 'danger' });
    }
    setShowProgress(false);
  };

  const getActiveEvents = async (id: string, event_name: string) => {
    setShowProgress(true);
    let params = `event_name=${event_name}`;
    if (id) {
      params = `market_id=${id}`;
    }
    try {
      const activeEventsResp = await getApi(`${BASEURL}/get_active_events?${params}`);
      if (activeEventsResp?.data?.length > 0) {
        const activeEvents = activeEventsResp.data;
        const cols = Object.keys(activeEvents[0]);
        cols.push('Mark Complete', 'Dashboard');
        tableActiveDataRef.current['columns'] = createColumns(cols);
        tableActiveDataRef.current['rows'] = activeEvents;
      } else {
        tableActiveDataRef.current = { columns: [], rows: [] };
        // setFlashContent({ message: activeEventsResp.message, status: 'danger' });
      }
    } catch (err) {
      tableActiveDataRef.current = { columns: [], rows: [] };
      setFlashContent({ message: String(err), status: 'danger' });
    }
    setShowProgress(false);
  };

  const getUpcomingEvents = async (market_id: string) => {
      try {
        setShowProgress(true);
        const res = await getApi(`${BASEURL}/get_upcoming_events?marketid=${market_id}`);
        if (res && res.data && res.data.length > 0) {
          const cols = Object.keys(res.data[0]);
          if(role !== 'admin'){
            cols.push('Subscribe');
          }
          tableUpcomingDataRef.current['columns'] = createColumns(cols);
          tableUpcomingDataRef.current['rows'] = res.data;
        } else {
          tableUpcomingDataRef.current = { columns: [], rows: [] };
          // setFlashContent({ message: res.message, status: 'danger' });
        }
      } catch (err) {
        tableUpcomingDataRef.current = { columns: [], rows: [] };
        setFlashContent({ message: String(err), status: 'danger' });
      }
      setShowProgress(false);
  } 

  const getEventActiveData = async (option: Market | any) => {
    setSelectedOption(option);
    if (option) {
      setEventName('');
      if ( !option.id ) {
        throw new Error('Event name expected but not found')
      }
      marketIDRef.current = option.id;
      await getActiveEvents(option.id, '');
      await getCompleteEvents(option.id, '');
      await getUpcomingEvents(option.id);
    } else {
      marketIDRef.current = '';
    }
  };

  useEffect(() => {
    getMarketNames();
    getAreaEventCodes();
  }, []);

  const handleOk = async () => {
    setShowProgress(true);
    try {
      const res = await postApi(`${BASEURL}/update_event`,{EventAreaCode:eventAreaCode, Vendor:vendor});
      if(res.status >= 200 && res.status < 300){
        setShowProgress(false);
        getEventActiveData(selectedOption);
        setFlashContent({ message: res.data.message, status: 'success' });
      }else{
        setFlashContent({ message: res.data.message, status: 'danger' });
      }
    } catch (err) {
      setFlashContent({ message: String(err), status: 'danger' });
    }
  };

  const submitSelectedFiles = async (selectedFiles: File[]) => {
    setShowProgress(true);
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('live_data', String(checked) )
    try {
      //TBD: Use postApi if it can return axios response (not just the response.data)?
      //const response = await postApi(`${BASEURL}/upload_files`, formData);
      const response = await fetch(`${BASEURL}/upload_files`, {
         method: 'POST',
         headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${getAccessToken()}`
        },
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        if (data && data.status_code === 200) {
          setShowModal(false);
          setFlashContent({ message: data.message, status: 'success' });
        } else {
          const error_message = (data && data.message) || "Received null data from the server"
          setFlashContent({ message: error_message, status: 'danger' });
        }
      } else {
        setFlashContent({ message: 'Upload failed', status: 'danger' });
      }
      setShowProgress(false);
    } catch (error) {
      setFlashContent({ message: String(error), status: 'danger' });
    }
  };

  const onChangeEvent = (value: string) => {
    setEventName(value);
  };

  const exportReport = async () => {
    setShowProgress(true);
    const eventAreaCode = powerBIInfoRef.current?.['EventAreaCode']
    const payload = {'vendor': powerBIInfoRef.current?.['Vendor'], 'EventAreaCode': eventAreaCode, 'report_type':  selectedColRef.current, 'start_date': powerBIInfoRef.current?.['Start Date'], 'end_date': powerBIInfoRef.current?.['End Date']}
    try {
      const response = await fetch(`${BASEURL}/export_report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Additional headers if needed (e.g., Authorization)
        },
        body: JSON.stringify(payload),
      });
      setShowProgress(false);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', eventAreaCode+'_COP.pptx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      setShowProgress(false);
      console.error('Failed to download the file:', error);
    }
  }

  return (
    <div className="row">
      {showProgress && <CircularProgress />}
      {showDashModal && powerBIInfoRef.current && (
        <NmaasModal
          showModal={showDashModal}
          setShowModal={setShowDashModal}
          title={powerBIInfoRef.current?.['Event Name']}
          fullscreen={true}
          toggelButton = {selectedColRef.current === 'COP' && <Button className="btn-custom-primary" size="sm" onClick={exportReport}>Export</Button>}
          body={<NmaasPowerBIReport powerBIInfo={{...powerBIInfoRef.current,EventAreaCode:(powerBIInfoRef.current as any)['EventAreaCode']}} />}
        />
      )}
      {flashContent.message && flashContent.status && (
        <FlashMessage
          message={flashContent.message}
          duration={3000}
          status={flashContent.status}
          onClose={() => setFlashContent({ message: '', status: undefined })}
        />
      )}
      {showModal && (
        <NmaasModal
          showModal={showModal}
          setShowModal={setShowModal}
          title={titleRef.current !== 'confirm' ? titleRef.current : ''}
          size={titleRef.current === 'confirm' ? 'sm' : undefined}
          toggelButton = {titleRef.current === 'Upload the data' ? 
            <div className='col-sm-auto switch-btn'>
              <Form.Label className='col-form-label col-sm-auto'>Live Data:</Form.Label>
              <Form>
                <Form.Check
                    type="switch"
                    id="custome-switch"
                    defaultChecked={checked}
                    onChange={handleChange}
                    style={{'transform': 'scale(2)'}}
                    title='Live Data'
                ></Form.Check>
              </Form>
            </div> : ""}
          body={
            titleRef.current === 'Event Form' ? (
              <DynamicForm
                fields={FIELDS}
                onSubmit={getFormData}
                options1={markets}
                options2={VENDOR_NAMES}
                checkValues={areaEventCodes}
                datepic={true}
              />
            ) : titleRef.current === 'confirm' ? (
              <div className="row confirm-modal">
                <div className="row confirm-header">
                    <span>Mark the Event Complete?</span>
                </div>
                <div className="col-10 save-button">
                  <Button className="btn-custom-primary" size="sm" onClick={(e) => { handleOk(); setShowModal(false); }}>
                    OK
                  </Button>{' '}
                  <Button className="btn-custom-secondary" size="sm" onClick={(e) => setShowModal(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <FileUpload onSubmit={submitSelectedFiles} />
            )
          }
        />
      )}
      <div className="row events-header">
        <h2>Events</h2>
      </div>
      <div className="row event-container">
        <div className="row market-names">
          <div className="col-4 market-dropdown">
            <label>Select Market</label>
            <Select
              options={markets}
              isMulti={false}
              onChange={getEventActiveData}
              isSearchable={true}
              placeholder="Please Select a Market"
              isClearable={true}
              isDisabled={eventName !== ''}
            />
          </div>
          <div className="col-1 show-middle">
            <span>OR</span>
          </div>
          <div className="col-3">
            <div className="row">
              <Form.Group className="event-search" controlId="searchForm">
                <Form.Control
                  type="text"
                  onChange={(e) => onChangeEvent(e.target.value)}
                  placeholder="Search by event name "
                  value={eventName}
                  disabled={marketIDRef.current !== ''}
                />
                <i className="bi bi-search search-icon" onClick={() => { getActiveEvents('', eventName); getCompleteEvents('', eventName) }}></i>
              </Form.Group>
            </div>
          </div>
          <div className="col-1 show-middle"></div>
          <div className="col-3 show-middle">
            {role && HIDE_ADD_UPLOAD.includes(role) ? (
              ''
            ) : (
              <>
                <Button className="btn-custom-primary" size="sm" onClick={addEvent}>
                  Add Event
                </Button>{' '}
                <Button className="btn-custom-secondary upload-button" size="sm" onClick={uploadData}>
                  Upload Data
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="row event-table">
            <MyDataTable columns={tableActiveDataRef.current['columns']} rows={tableActiveDataRef.current['rows']} title="Active Events" />
          </div>
        </div>
        <div className="col-12">
          <div className="row event-table">
            <MyDataTable columns={tableCompleteDataRef.current['columns']} rows={tableCompleteDataRef.current['rows']} title="Completed Events" />
          </div>
        </div>

        <div className="col-12">
          <div className="row event-table">
            <MyDataTable columns={tableUpcomingDataRef.current['columns']} rows={tableUpcomingDataRef.current['rows']} title="Upcoming Events" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;