import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

import FlashMessage, { FlashStatus } from './FlashMessage';
import { BASEURL } from '../constant';
import postApi from '../api/postApi';

// Assuming your row structure, adjust as necessary
interface DataRow {
  markets: any;
  user_id: string;
  name: string;
  // Add other row properties here
}

interface MyDataTableProps {
  columns: any[];
  rows: DataRow[];
  [key: string]: any;
}

const MyDataTableEdit: React.FC<MyDataTableProps> = ({ columns, rows, title, allMarkets }) => {
    const [flashContent, setFlashContent] = useState<{message:string,status?:FlashStatus}>({ message: '' });

  const [showAddMarketDropdown, setShowAddMarketDropdown] = useState<{ [key: string]: boolean }>({});
  const [editableRowId, setEditableRowId] = useState<string | null>(null);
  const [editedRows, setEditedRows] = useState<{ [key: string]: DataRow }>({});
  const handleEditClick = (row: DataRow) => {
    const isCurrentlyEditing = editableRowId === row.user_id;
    setEditableRowId(isCurrentlyEditing ? null : row.user_id);
    if (!isCurrentlyEditing) {
      setEditedRows(prev => ({ ...prev, [row.user_id]: row }));
    }
  };

  const handleSaveClick = async (userId: string) => {
    const payload = {'user_id': '', 'market_ids': []}
    const editedData = editedRows[userId]
    const marketIds = editedData.markets.filter((market: { market_id: any; }) => market.market_id).map((market: { market_id: any; }) => market.market_id);
    // Here you would typically save the edited row to your backend
    payload['user_id'] = userId
    payload['market_ids'] = marketIds
    const res = await postApi(`${BASEURL}/update_user_markets`, payload);
    if (res) {
      if(res.status >= 200 && res.status < 300){
        setFlashContent({ message: res.data.message, status: 'success' });
      }else{
        setFlashContent({ message: "Some error", status: 'danger' });
      }
    } else {
      setFlashContent({ message: "Some error", status: 'danger' });
    }

    setEditableRowId(null); 
  };

  const handleAddMarket = (userId: string, marketValue: string) => {
    console.log(userId, marketValue, allMarkets)
    const newMarket = allMarkets.find((market: { name: string; }) => market.name === marketValue);
    console.log(newMarket)
    if (newMarket) {
      setEditedRows(prev => ({
        ...prev,
        [userId]: {
          ...prev[userId],
          markets: [...prev[userId].markets, { name: newMarket.name, market_id: newMarket.market_id }],
        },
      }));
    }
    setShowAddMarketDropdown(prev => ({ ...prev, [userId]: false }));
  };

  const handleAddMarketClick = (userId: string) => {
    setShowAddMarketDropdown(prev => ({ ...prev, [userId]: !prev[userId] }));
  };
  
  const handleRemoveMarket = (userId: string, marketId: number) => {
    setEditedRows(prev => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        markets: prev[userId].markets.filter((market: any) => market.market_id !== marketId),
      },
    }));
  };

  const createMarketsRows = (row: DataRow) => {
    const isEditing = editableRowId === row.user_id;
    return (
      <div>
        {row.markets.map((market: { name: string; market_id: number; }, index: number) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px' }}>{market.name}</span>
            {isEditing && (
              <button onClick={() => handleRemoveMarket(row.user_id, market.market_id)}>-</button>
            )}
          </div>
        ))}
        {isEditing && showAddMarketDropdown[row.user_id] && (
          <select onChange={(e) => handleAddMarket(row.user_id, e.target.value)} value="">
            <option value="">Select market...</option>
            {allMarkets.map((market: { id: React.Key | null | undefined; name: string}) => (
              <option key={Math.random().toString()} value={market.name}>{market.name}</option>
            ))}
          </select>
        )}
        {isEditing && !showAddMarketDropdown[row.user_id] && (
          <button onClick={() => handleAddMarketClick(row.user_id)} style={{ marginTop: '10px' }}>+</button>
        )}
      </div>
    );
  };

  const editableColumns = columns.map(col => ({
    ...col,
    cell: (row: DataRow) => {
      if (col.name === 'markets') {
        return createMarketsRows(row);
      }
      // Handle other columns that might need custom rendering here
      return row[col.name as keyof DataRow];
    },
  }));

  // Ensure 'Edit' column is added only once
  if (!editableColumns.some(col => col.name === 'Edit')) {
    editableColumns.push({
      name: 'Edit',
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      cell: (row: DataRow) => (
        editableRowId === row.user_id ? (
          <button onClick={() => handleSaveClick(row.user_id)}>Save</button>
        ) : (
          <button onClick={() => handleEditClick(row)}>Edit</button>
        )
      ),
    });
  }

  // Merge original rows with edited rows
  const mergedRows = rows.map(originalRow => {
    const editedRow = editedRows[originalRow.user_id];
    return editedRow ? { ...originalRow, ...editedRow } : originalRow;
  });

  return (
    <>
        {flashContent.message && flashContent.status && (
            <FlashMessage
            message={flashContent.message}
            duration={3000}
            status={flashContent.status}
            onClose={() => setFlashContent({ message: '', status: undefined })}
            />
        )}
        <DataTable
            columns={editableColumns}
            data={mergedRows}
            pagination
            paginationPerPage={5}
            title={title}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
    </>
    
  );
};

export default MyDataTableEdit;

