import React, { ChangeEvent, ChangeEventHandler, FormEvent, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CircularProgress from './CircularProgress';
import { BASEURL } from '../constant';
import postApi from '../api/postApi';
import FlashMessage from './FlashMessage';

const ContactUsF = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [showProgress, setShowProgress] = useState(false)
    const [flashMessageObj, setFlashMessageObj] = useState<{message:string,status:"success" | "danger" | "warning" | "info"}>({message:'',status:"info"});

    const handleChange: ChangeEventHandler<HTMLInputElement|HTMLTextAreaElement> = (e: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        // Add your form submission logic here
        setShowProgress(true)
        try {
            const res = await postApi(`${BASEURL}/send_email`, formData)
            setShowProgress(false)
            // getEventActiveData(selectedOption)
            if(res.status >= 200 && res.status < 300){
                setFlashMessageObj({ message: res.data.message, status: "success" })
            }else{
                setFlashMessageObj({ message: res.data.message, status: "danger" })
            }
        } catch (err) {
            setShowProgress(true)
            setFlashMessageObj({ message: String(err), status: "danger" })
        }
    };

    return (
    <div className="container">
        {showProgress && <CircularProgress />}

        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="name" className="form-label">
                Name
                </label>
                <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">
                Email
                </label>
                <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="message" className="form-label">
                Message
                </label>
                <textarea
                className="form-control"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={4}
                required
                ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">
                Submit
            </button>
        </form>
        {flashMessageObj.message && (
            <FlashMessage
                message={flashMessageObj.message}
                duration={3000}
                status={flashMessageObj.status}
                onClose={() => setFlashMessageObj({ message: "", status: "info" })} />
        )}
    </div>
  );
};

export default ContactUsF;
