import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { ROLE, BASEURL } from '../constant';
import postApi from '../api/postApi';
import getApi from '../api/getApi';

interface Market {
  value: string;
  label: string;
  id: string;
}

export default function AddUpcomingEvents() {
  const [markets, setMarkets] = useState<Market[]>([]);
  const [market, setMarket] = useState<Market | null>({ value: '', label: '', id: "" });;
  const [eventname, setEventname] = useState('');
  const [apiResMessage, setApiResMessage] = useState('');
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const role = localStorage.getItem('role');

  useEffect(() => {
    const getMarketNames = async () => {
      // setShowProgress(true);
      const response = await getApi(`${BASEURL}/markets_names?role=${role}`);
      // setShowProgress(false);
      const marketOutput: Market[] = [];
      if (response && response.data) {
        response.data.forEach((colObj: any) => {
          const market = colObj['market']
          marketOutput.push({ value: market, label: market, id: colObj['id'] });
        });
        setMarkets(marketOutput);
      } else {
        const error = response.error || 'Network error!';
        // setFlashContent({ message: error, status: 'danger' });
      }
    };
    getMarketNames()
  }, [])

  const getEventActiveData = async (option: Market | null) => {
    setMarket(option)
  }

  const resetForm = () => {
    setEventname('');
    setMarket(null);
    setStartDate("");
    setEndDate("");
    setApiResMessage("")
  };

  const handleSubmit = async () => {
    const payload = {
      eventname: eventname,
      market_id: market && market['id'],
      startDate: startDate,
      endDate: endDate,
      status: 'Not Subscribed'
    };
    const res = await postApi(`${BASEURL}/add_upcoming_events`, payload);
    if (res) {
      if(res.status >= 200 && res.status < 300){
        setApiResMessage(res.data.message);
      }else{
        setApiResMessage("Some error")
      }
    } else {
      setApiResMessage("Some error")
    }
    resetForm();
  };

  return (
    <div className="row admin-page">
      <div className="col-12 registration-text">
        <h2>Add Upcoming Events</h2>
      </div>
      <div className="row">
        <div className="col-3"></div>
        <div className="col-6">
            <label>Event Name</label>
            <input
              type="text"
              className="form-control"
              id="eventname"
              value={eventname}
              name="eventname"
              aria-describedby="eventname"
              placeholder="Enter Event Name"
              onChange={(event) => setEventname(event.target.value)}
            />
            <div className="col-12 market-dropdown">
              <label>Select Market</label>
              <Select
                options={markets}
                isMulti={false}
                onChange={getEventActiveData}
                value={market}
                isSearchable={true}
                placeholder="Please Select a Market"
                isClearable={true}
                // isDisabled={eventName !== ''}
              />
            <div className='col-12' key="startDateField">
              <Form.Group className="mb-3" controlId='fromInputStartDate'>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type='date'
                  onChange={(e) => setStartDate(e.target.value)}
                  name='startDate'
                  value={startDate}
                  placeholder="Date Range"
                />
              </Form.Group>
            </div>

            <div className='col-12' key="endDateField">
              <Form.Group className="mb-3" controlId='fromInputEndDate'>
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type='date'
                  onChange={(e) => setEndDate(e.target.value)}
                  name='endDate'
                  value={endDate}
                  min={startDate} // Set the min attribute to the value of startDate
                  placeholder="Date Range"
                />
              </Form.Group>
            </div>

          </div>
         
          
          <div className="row submit">
            <button
              type="submit"
              className="btn-custom-primary"
              onClick={(e) => handleSubmit()}
              disabled={
                eventname === '' || market === null || startDate === "" || endDate === ""
              }
            >
              Submit
            </button>
            <small id="apiError" className="text-success form-text">
              {apiResMessage}
            </small>
          </div>
        </div>
        <div className="col-3"></div>
      </div>
    </div>
  );
}
