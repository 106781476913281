import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Dot } from 'recharts';

interface ReLineChartProps {
  data: Array<{
    name: string;
    'Event Count': number;
    color: string; // Intended for dot color, not line color
  }>;
}

const ReLineChart: React.FC<ReLineChartProps> = ({ data }) => (
  <div style={{ width: '100%', height: 250 }}>
    <ResponsiveContainer>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="Event Count"
          stroke="#ff8042" // Default stroke color for the line
          activeDot={{ r: 8 }}
          dot={(props) => {
            // Custom dot rendering based on data point color
            const { stroke, cx, cy, payload } = props;
            return <circle cx={cx} cy={cy} r={6} fill={payload.color} stroke={stroke} strokeWidth={2} />;
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

export default ReLineChart;
