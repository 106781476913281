import React, { useState } from "react";

import NmaasModal from "./components/NmaasModal";
import OurServices from "./components/OurServices";
import ContactUs from "./components/ContactUs";

interface ModalContent {
  title: string;
  tab: string;
}

export default function AboutUs() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContent>({ title: '', tab: '' });

  const displayContent = (tab: string) => {
    if (tab === 'services') {
      setModalContent({ title: 'Our Services', tab });
      setShowModal(true);
    } else if (tab === 'circet') {
      window.open("https://www.circetusa.com/building-telecommunications-infrastructure", "_blank");
    } else {
      setModalContent({ title: 'Contact Us', tab });
      setShowModal(true);
    }
  }

  return (
    <div className="row">
      <div className="col-4 card capacity">
        <div className="card-body">
          <h1 className="card-title">Our Services</h1>
          <p className="card-info">CircetUSA offers comprehensive event monitoring services, including 24/7 engineering support, software automation for competitive pricing, extensive experience in Nokia and Ericsson systems, and specialized monitoring for various large-scale events and networks.</p>
          <button className="card-btn" onClick={() => displayContent('services')}>More...</button>
        </div>
      </div>

      <div className="col-4 card redirect">
        <div className="card-body">
          <h1 className="card-title">Circet USA</h1>
          <p className="card-info">Circet USA is a leading network services provider, with tailored services including the design, engineering, construction, and maintenance of wireline and wireless networks.</p>
          <button className="card-btn" onClick={() => displayContent('circet')}>Visit...</button>
        </div>
      </div>

      <div className="col-4 card contact-us">
        <div className="card-body">
          <h1 className="card-title">Contact Us</h1>
          <p className="card-info">For any questions or concerns, please contact us.</p>
          <button className="card-btn" onClick={() => displayContent('')}>Contact</button>
        </div>
      </div>

      {/* ===================================== Modal PopUP =================== */}

      <NmaasModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={modalContent.title}
        size="xl"
        body={modalContent.tab === "services" ? <OurServices /> : <ContactUs />}
      />

    </div>
  );
}
