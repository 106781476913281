import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from './images/circet_logo.png';
import { BASEURL } from './constant';
import postApi from './api/postApi';
import { login as authLogin } from './auth';

interface LoginProps {
  onLogin?: () => void;
}

export default function Login({ onLogin }: LoginProps) {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [apiError, setApiError] = useState<string>('');
  const [busy, setBusy] = useState<boolean>(false);
  const navigate = useNavigate();

  const clearErrors = () => {
    setEmailError('');
    setPasswordError('');
    setApiError('');
  }

  const handleLogin = async () => {
    if (!email) {
      setEmailError('Please enter email!');
      return;
    }
    if (!password) {
      setPasswordError('Please Enter password!');
      return;
    }
    try {
      clearErrors();
      setBusy(true);
      const res = await postApi(`${BASEURL}/login`, { email, password });
      if (res) {
        if(res.status >= 200 && res.status < 300){
            if(res.data && res.data.status_code === 200){
              authLogin(res.data.data, res.data.access_token);
              navigate('/home');
              onLogin?.();
            }else{
              setApiError(res.data.message ||`Missing login data (empty reply from server)`);
            }
        }else{
          setApiError(res?.error||`Missing login data (empty reply from server)`);
        }
      } else {
        setApiError(res?.error||`Missing login data (empty reply from server)`);
      }
    } catch (err: any) {
      setApiError(err.message || String(err));
    } finally {
      setBusy(false);
    }
  };

  return (
    <div className="row login-content">
      <div className="header d-flex flex-column align-items-center">
        <img src={logo} alt="Logo" />
        <p className="subtitle">Network Monitoring as a Service Tool</p>
      </div>
      <div className="col-4"></div>
      <div className="col-4 login-container">
        <div className="row d-flex justify-content-center">
          <form id="loginform">
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="emailid"
                aria-describedby="email"
                placeholder="Enter Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                onKeyDown={(event) => {event.key === 'Enter' && handleLogin()}}
              />
              <small id="emailHelp" className="text-danger form-text">
                {emailError}
              </small>
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={(event) => event.key === 'Enter' && handleLogin()}
              />
              <small id="passworderror" className="text-danger form-text">
                {passwordError}
              </small>
              <small id="apiError" className="text-danger form-text">
                {apiError}
              </small>
            </div>
          </form>
          <div className="row submit">
            <button
              type="submit"
              className="btn-custom-primary"
              onClick={handleLogin}
              disabled={busy || email === '' || password === ''}
            >
              {busy && <div className="spinner-border spinner-border-sm me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              }
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className="col-4"></div>
    </div>
  );
}
