import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

export type FlashStatus = 'success' | 'danger' | 'warning' | 'info';

interface FlashMessageProps {
  message: string;
  status: FlashStatus;
  duration: number;
  onClose: () => void;
}

const FlashMessage: React.FC<FlashMessageProps> = ({
  message,
  status,
  duration,
  onClose,
}: FlashMessageProps) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      onClose();
    }, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className='row'>
      <div className='col-2'></div>
      <div className='col-8'>
        <div className='alert-message'>
          <Alert show={show} variant={status}>
            {message}
          </Alert>
        </div>
      </div>
      <div className='col-2'></div>
    </div>
  );
};

export default FlashMessage;
