import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import { ROLE, BASEURL } from '../constant';
import postApi from '../api/postApi';

export default function UserRegistration() {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [role, setRole] = useState('');
  const [apiResMessage, setApiResMessage] = useState('');

  const changeRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const val = e.target.value;
    if (val) {
      setRole(val);
    }
  };

  const resetForm = () => {
    setFname('');
    setLname('');
    setPassword('');
    setCPassword('');
    setRole('');
    setEmail('');
    // setApiResMessage("")
  };

  const handleSubmit = async () => {
    const payload = {
      firstname: fname,
      lastname: lname,
      password: password,
      email: email,
      role: role,
    };
    const res = await postApi(`${BASEURL}/registration`, payload);
    if (res) {
      if(res.status >= 200 && res.status < 300){
        setApiResMessage(res.data.message);
      }else{
        console.log(res);
      }
    } else {
      console.log(res);
    }
    resetForm();
  };

  return (
    <div className="row admin-page">
      <div className="col-12 registration-text">
        <h2>User Registration Page</h2>
      </div>
      <div className="row">
        <div className="col-3">

        </div>
        <div className="col-6">
          <div className="row form-group">
            <label>First Name</label>
            <input
              type="text"
              className="form-control"
              id="fname"
              value={fname}
              name="firstname"
              aria-describedby="fname"
              placeholder="Enter First Name"
              onChange={(event) => setFname(event.target.value)}
            />
          </div>
          <div className="row form-group">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control"
              id="lname"
              value={lname}
              name="lastname"
              aria-describedby="lname"
              placeholder="Enter Last Name"
              onChange={(event) => setLname(event.target.value)}
            />
          </div>
          <div className="row form-group">
            <label>Role</label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => changeRole(e)}
            >
              <option value="" key="99">
                Please select role
              </option>
              {ROLE.map((role, ind) => {
                return (
                  <option value={role} key={ind}>
                    {role}
                  </option>
                );
              })}
            </Form.Select>
          </div>
          <div className="row form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="emailid"
              value={email}
              aria-describedby="email"
              placeholder="Enter Email"
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="row form-group">
            <label>Password</label>
            <input
              type="password"
              className={`form-control ${
                password && cpassword && password !== cpassword
                  ? 'password-not-match'
                  : ''
              }`}
              id="exampleInputPassword1"
              value={password}
              placeholder="Password"
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <div className="row form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              className={`form-control ${
                password && cpassword && password !== cpassword
                  ? 'password-not-match'
                  : ''
              }`}
              id="exampleInputPassword2"
              value={cpassword}
              placeholder="Confirm Password"
              onChange={(event) => setCPassword(event.target.value)}
            />
          </div>
          <div className="row submit">
            <button
              type="submit"
              className="btn-custom-primary"
              onClick={(e) => handleSubmit()}
              disabled={
                fname === '' ||
                lname === '' ||
                email === '' ||
                password === '' ||
                cpassword === '' ||
                password !== cpassword ||
                role === ''
              }
            >
              Submit
            </button>
            <small id="apiError" className="text-success form-text">
              {apiResMessage}
            </small>
          </div>
        </div>
        <div className="col-3"></div>
      </div>
    </div>
  );
}
