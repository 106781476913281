import React from 'react';

const CircularProgress: React.FC = () => {
  return (
    <div className='progress-container'>
      <div className='progress-circle'></div>
    </div>
  );
};

export default CircularProgress;
