import React from 'react';
import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts';

interface CircularChartProps {
  data: Array<{
    value: number;
    color: string;
    [key: string]: any;
  }>;
}

const CircularChart: React.FC<CircularChartProps> = ({ data }) => (
  <div style={{ width: '100%', height: 250 }}>
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          // cx={200}
          // cy={200}
          innerRadius={40}  // Adjust for thickness
          outerRadius={100} // Adjust for thickness
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  </div>
);

export default CircularChart;
