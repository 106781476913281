import React, { useState } from 'react';
import Select from 'react-select';
import { Button, Form } from 'react-bootstrap';

interface Field {
  name: string;
  label: string;
  type?: string;
}

interface Props {
  fields: Field[];
  onSubmit: (
    formData: Record<string, any>,
    selectedMarket: any,
    selectedDate: string,
    selectedVendor: any,
    selectedEndDate: string
  ) => void;
  options1?: any[];
  options2?: any[];
  checkValues: Record<string, any>;
  datepic: boolean;
}

const DynamicForm: React.FC<Props> = ({
  fields,
  onSubmit,
  options1,
  options2,
  checkValues,
  datepic,
}) => {
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedEndDate, setSelectedEndDate] = useState<string>("");
  const [selectedMarket, setSelectedMarket] = useState<any>(null);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const [error, setError] = useState<Record<string, string>>({
    EventAreaCode: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'EventAreaCode') {
      if (checkValues.includes(value)) {
        setError({ EventAreaCode: "Event area code already exists!" });
      } else {
        setError({ EventAreaCode: "" });
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(formData, selectedMarket, selectedDate, selectedVendor, selectedEndDate);
      setFormData({});
      setSelectedMarket(null);
      setSelectedVendor(null);
      setSelectedDate("");
      setSelectedEndDate("");
  };

  const changeMarket = (option: any) => {
    setSelectedMarket(option);
  };
  const changeVendor = (option: any) => {
    setSelectedVendor(option);
  };

  const checkFormdata = () => {
    let isTrue = false;
    Object.keys(formData).forEach((key) => {
      if (formData[key] === "") {
        isTrue = true;
      }
    });
    if (
      fields.length !== Object.keys(formData).length ||
      selectedDate === "" ||
      selectedMarket === null ||
      selectedVendor === "" ||
      selectedEndDate === ""
    ) {
      isTrue = true;
    }
    if(error['EventAreaCode'] !== ""){
      isTrue = true;
    }
    return isTrue;
  };

  return (
    <form onSubmit={handleSubmit}>
      {options1 && options1.length > 0 && (
        <div className='col-12' key="marketInput">
          <div className="mb-3">
            <label>Select Market</label>
            <Select
              options={options1}
              onChange={changeMarket}
              placeholder="Please select the Market"
              value={selectedMarket}
            />
          </div>
        </div>
      )}
      {options2 && options2.length > 0 && (
        <div className='col-12' key="vendorInput">
          <div className="mb-3">
            <label>Select Vendor</label>
            <Select
              options={options2}
              isMulti={false}
              onChange={changeVendor}
              placeholder="Please select the Vendor"
              value={selectedVendor}
            />
          </div>
        </div>
      )}
      {fields.map((field, ind) => {
        return (
          <div className='col-12' key={field.name}>
            <Form.Group className="mb-3" controlId={`fromInput-${ind}`}>
              <Form.Label>{field.label}</Form.Label>
              <Form.Control
                type={field.type || 'text'}
                onChange={handleChange}
                name={field.name}
                value={formData[field.name] || ""}
                placeholder={`Enter ${field.label}`}
              />
              <small id="errorHelp" className="text-danger form-text">
                {error[field.name]}
              </small>
            </Form.Group>
          </div>
        );
      })}
      {datepic && (
        <>
          <div className='col-12' key="datefield">
            <Form.Group className="mb-3" controlId='fromInputdate'>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type='date'
                onChange={(e) => setSelectedDate(e.target.value)}
                name='datepic'
                value={selectedDate}
                placeholder="Date Range"
              />
            </Form.Group>
          </div>
          <div className='col-12' key="datefield">
            <Form.Group className="mb-3" controlId='fromInputdate'>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type='date'
                onChange={(e) => setSelectedEndDate(e.target.value)}
                name='datepic'
                value={selectedEndDate}
                placeholder="Date Range"
              />
            </Form.Group>
          </div>
          <div className='col-12 save-button'>
            <Button
              className="btn-custom-primary"
              type="submit"
              disabled={checkFormdata()}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default DynamicForm;
