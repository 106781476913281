import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface NmaasModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  title?: string;
  body: React.ReactNode;
  size?: 'sm' | 'lg' | 'xl';
  fullscreen?: true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down';
  toggelButton?: React.ReactNode;
}

const NmaasModal: React.FC<NmaasModalProps> = ({
  showModal,
  setShowModal,
  title,
  body,
  size,
  fullscreen,
  toggelButton,
}) => {
  const handleClose = () => setShowModal(false);

  return (
    <Modal show={showModal} onHide={handleClose} size={size || 'lg'} fullscreen={fullscreen} centered>
      {title && (
        <Modal.Header closeButton style={{'position' : 'relative'}}>
          <Modal.Title>
            {title} 
          </Modal.Title>
          <div className='toggle-btn'>{toggelButton}</div>
        </Modal.Header>
      )}
      <Modal.Body>{body}</Modal.Body>
    </Modal>
  );
};

export default NmaasModal;
