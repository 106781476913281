import React, { useEffect } from 'react';
import { MapContainer, TileLayer, useMap, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css'; // MarkerCluster default styles
import L from 'leaflet';
import * as d3 from 'd3';

// Define an interface for the city data expected from the CSV file
interface CityData {
  lat: string;
  lon: string;
  name: string;
}

const orangeCircleIcon = new L.Icon({
  iconUrl: `${process.env.PUBLIC_URL}/circle-16.png`, // Path to your custom orange circle marker image
  iconSize: [10, 10], // Size of the icon
  iconAnchor: [5, 5], // Point of the icon which will correspond to marker's location
});

const CityMarkers: React.FC = () => {
  const map = useMap();

  useEffect(() => {
    const processCSVData = async () => {
      try {
        // Assuming that the CSV is properly formatted to match the CityData interface
        const cities: CityData[] = await d3.csv(`${process.env.PUBLIC_URL}/us-cities.csv`);
        cities.forEach((city: CityData) => {
          const latLng: L.LatLngExpression = [parseFloat(city.lat), parseFloat(city.lon)]; 
          L.marker(latLng, {
            title: city.name,
            icon: orangeCircleIcon, 
          }).bindPopup(`<b>${city.name}</b>`).addTo(map);
        });
      } catch (error) {
        console.error("Error loading the city data: ", error);
      }
    };

    processCSVData();
  }, [map]);

  return null; 
};

const MapChart: React.FC = () => {
  return (
    <MapContainer center={[37.8, -96]} zoom={4} style={{ height: '500px', width: '100%' }}>
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <CityMarkers />
    </MapContainer>
  );
};

export default MapChart;
