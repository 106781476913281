import React from 'react';
import {
  PRJ_SPL_EV_MON,
  PRJ_WKN_MON_SRV,
  SRVS_SPL_EV_MON,
  SRVS_WKN_MON_SRV,
} from '../constant';

export default function OurServices(): JSX.Element {
  return (
    <>
      <div className="row nmas-home">
        <div className="col-6 projects">
          <div className="row spcl-evnt-body">
            {PRJ_SPL_EV_MON.map((item: string) => {
              return (
                <ul key={item}>
                  <li>{item}</li>
                </ul>
              );
            })}
          </div>
          <div className="row wkn-mon-body">
            {PRJ_WKN_MON_SRV.map((item: string) => {
              return (
                <ul key={item}>
                  <li>{item}</li>
                </ul>
              );
            })}
          </div>
        </div>

        <div className="col-6 projects">
          <div className="row spcl-evnt-body">
            {SRVS_SPL_EV_MON.map((item: string) => {
              return (
                <ul key={item}>
                  <li>{item}</li>
                </ul>
              );
            })}
          </div>
          <div className="row wkn-mon-body">
            {SRVS_WKN_MON_SRV.map((item: string) => {
              return (
                <ul key={item}>
                  <li>{item}</li>
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
