import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { BsBackspaceFill } from 'react-icons/bs';

interface FileUploadProps {
  onSubmit: (files: File[]) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ onSubmit }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileError, setFileError] = useState<string>('');

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (selectedFiles.length + acceptedFiles.length > 4) {
        setFileError('You should upload 4 files not less not more!');
      } else {
        const validFiles = acceptedFiles.filter(
          (file) =>
            file.type === 'text/csv' ||
            file.type === 'application/vnd.ms-excel' ||
            file.type === '.xlsx' ||
            file.type ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        if (validFiles.length > 0) {
          const uniqueFiles = acceptedFiles.filter((file) => {
            const isDuplicate = selectedFiles.some(
              (selectedFile) => selectedFile.name === file.name
            );
            if (isDuplicate) {
              setFileError(`Duplicate file: ${file.name}`);
            }
            return !isDuplicate;
          });
          setSelectedFiles([...selectedFiles, ...uniqueFiles]);
          setFileError('');
        } else {
          setFileError('Please select valid CSV, XLS & XLSX files.');
        }
      }
    },
    [selectedFiles]
  );

  const onSubmitFile = () => {
    onSubmit(selectedFiles);
  };

  const removeFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const acceptTypes = '.xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv';

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptTypes as any,
    multiple: true,
  });

  return (
    <div>
      <div {...getRootProps()} style={{ border: '1px dashed #ccc', padding: '10px' }}>
        <input {...getInputProps()} />
        <p>
          Drag 'n' drop some CSV or XLS files here, or click to select files.
          Please upload 4 files not less not more!
        </p>
        <p className='upload-note'>
          Important Note: While uploading Ericsson Data, Please turn off the Live Data toggle to prevent any data loss. 
        </p>
      </div>
      {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
      <h2>Selected Files:</h2>
      <ul>
        {selectedFiles.map((file, index) => (
          <li key={index}>
            {file.name}
            <BsBackspaceFill
              onClick={() => removeFile(index)}
              title="Remove the selected file"
            />
          </li>
        ))}
      </ul>
      <div className="row">
        <div className="col-10"></div>
        <div className="col-2">
          <Button className="btn-custom-primary" onClick={onSubmitFile}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
