import React, { useEffect, useState } from "react";

import ReBarChart from "./components/charts/BarChart";
import CircularChart from "./components/charts/CircularChart";
import MapChart from "./components/charts/MapChart";
import SlideShow from "./components/SlideShow";
import getApi from "./api/getApi";
import { BASEURL } from "./constant";
import CircularProgress from "./components/CircularProgress";

const importAll = (r: any) => r.keys().map(r);

const images = importAll((require as any).context('./Event logo', false, /\.(png|jpg)$/));

interface Testimonial {
  id: number;
  author: string;
  content: string;
}

export default function NmaasHome() {
  const [ eventCount, setEventCount ] = useState({"count": 617, 'pcount': 0})
  const [showProgress, setShowProgress] = useState(false);
  const userName = localStorage.getItem("userName");

  useEffect( ()  => {
    const getCompleteEvents =  async () => {
      setShowProgress(true);
      const response = await getApi(`${BASEURL}/get_completed_event_count`);
      setShowProgress(false);
      if (response && response.count >= 0) {
        setEventCount({"count" :eventCount['count'] + response.count, 'pcount': eventCount['pcount'] + response.pcount});
      } else {
        // const error = response?.message || 'Network error';
        console.log(response)
      }
    } 
    getCompleteEvents()    

  }, [])

  const testimonials: Testimonial[] = [
    {
      id: 1,
      author: 'RF Manager',
      content: '“I wanted to take a moment to thank you for your recent work in monitoring/optimizing our network. This event was a highly anticipated one which T-Mobile prepared for over many months with significant network investment. We needed to have our network perform at the highest level and your team was instrumental in helping us do that on each of the three days.”',
    },
    {
      id: 2,
      author: 'RF Manager',
      content: '“I am very impressed with the level of commitment, support, preparation, and execution for this event. FES has done a great job throughout the event. FES was consistent in his update, capturing issues live and taking action to re-adjust accordingly. We truly appreciate this partnership and look forward to future opportunities.”​',
    },
    // Add more testimonials as needed
  ];


  const barChartdata = [
    { name: 'Jan', "Event Count": 9, color: '#ff8042' },
    { name: 'Feb', "Event Count": 5, color: '#ff8042' },
    { name: 'Mar', "Event Count": 3, color: '#ff8042' },
    { name: 'Apr', "Event Count": 6, color: '#ff8042' },
    { name: 'May', "Event Count": 7, color: '#ff8042' },
    { name: 'Jun', "Event Count": 22, color: '#ff8042' },
    { name: 'Jul', "Event Count": 16, color: '#ff8042' },
    { name: 'Aug', "Event Count": 15, color: '#ff8042' },
    { name: 'Sep', "Event Count": 38, color: '#ff8042' },
    { name: 'Oct', "Event Count": 19, color: '#ff8042' },
    { name: 'Nov', "Event Count": 23, color: '#ff8042' },
    { name: 'Dec', "Event Count": 12, color: '#ff8042' },
  ];

  const CircularChartData = [
    { name: 'Completed', value: eventCount['count'], color: '#ff8042' },
    { name: 'In Progress', value: eventCount['pcount'], color: '#8884d8' },
    // ... more data
  ];

  const HighlightPoints = [
    "Experience in both Nokia and Ericsson OSS", "Standard Procedures Followed to Ensure Consistency and Compliance"
  ];

  return (
    <div className="row container-nmass-home">
      {showProgress && <CircularProgress />}

      <div className='nmass-home'>
        <div className="namas-component">
          <div className="namas-sub-cmp">
            <div className="nmass-numbers">
              <div className="header-number">
                <h5>Monitored Events:</h5>&nbsp;&nbsp;<h3 className="number">{eventCount['count']}</h3>
              </div>
              <div>
                <ul>
                  {HighlightPoints.map((item, ind) => {
                    return <li key={ind}>{item}</li>
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="row namas-sub-cmp">
            <div className="row">
              <div className="row slide-header">
                <p>Some Past Events</p>
                </div>
              <SlideShow contentList={images} type='img' />
            </div>
          </div>
          <div className="namas-sub-cmp">
            <SlideShow contentList={testimonials} />
          </div>
        </div>

        <div className="nsmass-welcome">
          <div className="namas-sub-cmp first-child">
            <div className="nmass-numbers">
              <div className="css-15goea3">
                <h4 className="MuiTypography-root MuiTypography-h5 css-1r9kbzm">
                  Welcome to Circet NMaaS Tool, {userName}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="nmass-charts">
          <div className="namas-sub-cmp first-child">
            <MapChart />
          </div>
          <div className="second-child">
            <div className="bar-chart">
              <ReBarChart data={barChartdata} />
            </div>
            <div className='row white-space'></div>
            <div className="circulat-chart">
              <CircularChart data={CircularChartData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


