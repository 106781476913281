import React from 'react';
import DataTable from 'react-data-table-component';

interface MyDataTableProps {
  columns: any[];
  rows: any[];
  title: string;
}

const MyDataTable: React.FC<MyDataTableProps> = ({ columns, rows, title }: MyDataTableProps) => {
  const sortableColumns = columns.map(column => ({
    ...column,
    sortable: true,
  }));

  return (
    <DataTable
      columns={sortableColumns}
      data={rows}
      pagination
      paginationPerPage={5}
      title={title}
      paginationRowsPerPageOptions={[5, 10, 15, 20]}
      // selectableRows
      // onSelectedRowsChange={getSelectedRow}
    />
  );
};

export default MyDataTable;