import axios, { AxiosResponse } from 'axios';
import { getAccessToken } from '../auth';

async function postApi(url: string, payload: any): Promise<any> {
  try {
    const response: AxiosResponse = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${getAccessToken()}`
      },
    });
    return response;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
        throw new Error(`Error submitting data (${error.message})`);
    }
    //return error as AxiosError;
    throw error
  }
}

export default postApi;
