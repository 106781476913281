import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, service } from 'powerbi-client';

export interface PowerBIInfo {
  embed_url: string;
  token: string;
  EventAreaCode: string; // Change this type to match your data
}

function NmaasPowerBIReport({ powerBIInfo }: { powerBIInfo: PowerBIInfo }): JSX.Element {
  // Configuration for embedding the report

  const evtAreaCodeFilter: models.IBasicFilter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'nmaas events',
      column: 'EventAreaCode',
    },        
    operator: 'In',
    values: [powerBIInfo.EventAreaCode],
    filterType: models.FilterType.Basic
  };

  const embedConfig: models.IReportEmbedConfiguration  = {
    type: 'report', // Supported types: report, dashboard, tile, visual
    embedUrl: powerBIInfo.embed_url,
    tokenType: models.TokenType.Embed, // Token type: Embed or Aad
    accessToken: powerBIInfo.token,
    settings: {
      zoomLevel: 0.5,
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
      background: models.BackgroundType.Transparent,
    },
    filters: [evtAreaCodeFilter],
  };

  return (
    <div>
      <PowerBIEmbed
        embedConfig={embedConfig}
        eventHandlers={
          new Map([
            ['loaded', function (event: service.ICustomEvent<any>|undefined) {
              console.log('Report loaded', event?.detail);
            }],
            ['rendered', function () {
              console.log('Report rendered');
            }],
            ['error', function (event) {
              console.log(event?.detail);
            }],
          ])
        }
        cssClassName={'report-style-class'}
      />
    </div>
  );
}

export default NmaasPowerBIReport;
