export const BASEURL = "//nmaas.furtherllc.com/api"
//export const BASEURL = "//nmaas.furtherllc.com/api"

export const ROLE = ["engineer", "customer", "admin"]

export const FIELDS = [{name: 'EventName', label: 'Event Name'}, {name: 'EventAreaCode', label: 'Event Area Code'},
{name: 'TMOManager', label: 'TMO Manager'}, {name: 'TMORFPOC', label: 'TMO RF POC'},
 {name: 'FESEngineer', label: 'FES Engineer'}]

export const VENDOR_NAMES = [{'value': 'Nokia', 'label': 'Nokia', 'id': 'Nokia'}, 
                                { 'value': 'Ericsson', 'label': 'Ericsson', 'id': 'Ericsson'}]

export const ADMINICON = 'bi bi-person-plus'

export const SIDEMENU = {'Home': 'bi bi-house', "Events": "bi bi-calendar2-event", 'About Us': "bi bi-person-rolodex"}

export const EVENT_TABLE_COLUMNS = ['Mark Complete','Dashboard']

export const HIDE_COLUMNS = ['FES Engineer', 'Mark Complete', 'COP']

export const HIDE_ADD_UPLOAD = ['customer']

export const PRJ_SPL_EV_MON = ['Team Of 7 Engineers ready to support week and weekends', 
    'Ready to support in 24H', 
    'Very aggressive prices due to SW automatization', 
    'Experience in Nokia and Ericsson Event Monitoring', 
    'Monitored 500* T-Mobile Events', 
    '18 markets served so far in 2023', 
    '11 NFL Stadium Monitored', 
    '7 College Football Stadium Monitored', 
    '2 Presidential Inaugrations' ]

export const PRJ_WKN_MON_SRV = ['Market Wide Network Monitoring during weekend and holidays', 
    'Experience in both Nokia and Ericsson OSS and ENM', 
    'Our Team of Engineers have Hands-On Experience on various TMO Tools and Network Strategy',
    'Standard procedures Followed to Ensure Consistency and Compliance',
    'Served more than 5 markets', 
    '10,000+ 4G and 5G sites monitored during the weekend']

export const SRVS_SPL_EV_MON = ['Data Collection & Benchmarking', 
    'Data Analysis & Optimization Recommendations', 
    'Fine Tuning Recommendations', 
    'Real Time Event Performance Monitoring', 
    'Real Time changes/optimization perform', 
    'Project Closeout and Reporting']

export const SRVS_WKN_MON_SRV = ['KPI Monitoring for Market Wide Network', 
    'Investigating Issues at Site/Cell level',
    'Mitigating issues on impacted cell to improve KPI Performance and maintaining an activity tracker', 
    'SI alarm check and reporting using OneConsole and Monitor', 
    'Trouble-Ticket for SI sites and communicating the allocated Field Engineer along With RF On-Call', 
    'Periodic updates of Network Status and Performance to the customer and Summary at the end of the day']

export const TESTIMONIALS = ['“I wanted to take a moment to thank you for your recent work in monitoring/optimizing our network. This event was a highly anticipated one which T-Mobile prepared for over many months with significant network investment. We needed to have our network perform at highest level and your team was instrumental in helping us do that on each of the three days.” ',
    'RF Manager',
    '“I am very impressed with the level of commitment, support, preparation and execution for this event. FES has done a great job throughout the event. FES was consistent in his update, capturing issues live and taking action to re-adjust accordingly. We truly appreciate this partnership and look forward for future opportunities.”​',
    'RF Manager']

export const SIDEMENU_SUB = ['User Registration', 'Add Upcoming Events', 'User Market Control']
