import axios, { AxiosResponse } from "axios";
import { getAccessToken } from "../auth";

async function getApi(url: string, payload?: any): Promise<any> {
  try {
    const response: AxiosResponse = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${getAccessToken()}`
      }
    });
    return response.data;
  } catch (error) {
    return error;
    // throw error
  }
}

export default getApi;
